import React from "react"
import { Link } from "gatsby"

import styled from "styled-components"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import BackgroundImage from "gatsby-background-image"
import logo from "../images/veracity-logo-white.svg"

import { respondTo } from "../_respondTo"

const LogoWrapper = styled.div`
  text-align: center;
  color: white;
  padding: 30px;
  margin: 0 auto;
  width: 90vw;
  max-height: 50vh;
  margin-top: 10vh;
  max-width: 1000px;
  a {
    color: white;
  }

  p {
    max-width: 960px;
    margin: auto;
  }

  ${respondTo.sm`
    margin-top: 25vh;
  `}
`

const NotFoundPage = props => (
  <Layout>
    <SEO title="Veracity Asset Transformation Service" />

    <BackgroundImage
      fluid={props.data.bgImage.childImageSharp.fluid}
      alt=""
      style={{
        height: "100vh",
        width: "100vw",
        position: "fixed",
        top: "0",
        left: "0",
        zIndex: "-9",
      }}
    ></BackgroundImage>

    <LogoWrapper>
      <img
        src={logo}
        alt="Veracity Asset Transformation Service logo"
        style={{ display: "block", margin: "0 auto 30px auto" }}
      ></img>

      <h2>Page Not Found</h2>
      <p>
        <Link to="/">Back to homepage</Link>
      </p>
      <p style={{ marginTop: "1em" }}>
        <strong>E-mail us:</strong>{" "}
        <a href="mailto:info@veracity-ats.com">info@veracity-ats.com</a>
      </p>
    </LogoWrapper>
  </Layout>
)

export default NotFoundPage

export const pageQuery = graphql`
  query {
    bgImage: file(relativePath: { eq: "bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1923, quality: 80) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
